import Typography from "components/Typography";
import Lottie from "lottie-react";
import moment from "moment";
import BusinessName from "./BusinessName";
import successIcon from "assets/success-green.json";
import { rupiah } from "lib/currency";
import { DataDetailCheckout, ItemOrder } from "../types";

interface SuccessResultProps {
  data: DataDetailCheckout;
}

const SuccessResult: React.FC<SuccessResultProps> = ({ data }) => {
  const addtionalData = [
    {
      label: "Tax",
      value: data?.tax ? `${data?.tax * 100}%` : "-",
    },
    {
      label: "Discount",
      value: data?.discount ? rupiah(data?.discount) : "-",
    },
    {
      label: "Fee",
      value: data?.fees ? rupiah(data?.fees) : "-",
    },
  ];

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="pb-6 pt-8 px-4 lg:px-0 w-full">
        <BusinessName data={data} />
        <div className="-mt-6 mx-auto text-center">
          <Lottie
            className="w-[350px] h-[250px] mx-auto"
            animationData={successIcon}
            loop={true}
          />
          <div className="-mt-10">
            <Typography
              variant="h4"
              className="font-semibold text-emerald-500 !leading-normal"
            >
              PAYMENT SUCCESFUL
            </Typography>
          </div>
          <div className="text-left bg-gray-100 p-4 rounded-md mt-8 mb-2 shadow-md">
            <span className="font-bold text-sm">
              Date:{" "}
              {data?.invoice_date
                ? moment(data?.invoice_date).format("DD MMM YYYY")
                : "-"}
            </span>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center text-xs text-gray-800 font-light">
                <span className="mr-1">Order</span>(
                <span className="w-[70%] block overflow-hidden whitespace-nowrap text-ellipsis">
                  {data?.po_number ? data?.po_number : "-"}
                </span>
                )
              </div>
            </div>
            <div className="mt-4 pt-4 border-t border-gray-300">
              <ul className="divide-y divide-gray-100 flex flex-col gap-[8px]">
                {data?.invoice_payment_items.map(
                  (item: ItemOrder, idx: number) => (
                    <li
                      key={idx}
                      className="flex justify-between items-center gap-x-6"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-bold text-gray-900 capitalize">
                            {item.item_name}
                          </p>
                          <p className="text-gray-600 text-xs font-medium">
                            {item.price ? rupiah(item.price) : "Rp -"} x{" "}
                            {item.quantity ? item.quantity : "0"}{" "}
                            <span className="text-[10px]">Qty</span>
                          </p>
                        </div>
                      </div>
                      <div className="shrink-0 flex flex-col items-start">
                        <p className="text-base text-gray-800 font-semibold">
                          {item ? rupiah(item.price * item.quantity) : "Rp -"}
                        </p>
                      </div>
                    </li>
                  )
                )}
              </ul>
              <div className="flex items-center justify-between text-gray-900 pt-3 pb-4">
                <dt className="text-gray-900 text-base font-semibold">
                  Subtotal
                </dt>
                <dd className="text-gray-900 text-lg font-bold">
                  {data?.subtotal ? rupiah(data?.subtotal) : "Rp -"}
                </dd>
              </div>
              <div className="flex flex-col gap-[5px] border-t border-gray-300 pt-4">
                {addtionalData.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center justify-between text-gray-900"
                    >
                      <dt className="text-gray-900 text-[14px] font-medium">
                        {item.label}
                      </dt>
                      <dd className="text-gray-900 text-[14px] font-medium">
                        {item.value}
                      </dd>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex items-end justify-between text-gray-900 px-4 pt-2 pb-2 bg-emerald-500 rounded-md">
            <dt className="text-white text-base font-bold">Total</dt>
            <dd className="text-white text-xl font-bold">
              {data?.total ? rupiah(data?.total) : "Rp -"}
            </dd>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessResult;
