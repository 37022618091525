import React from "react";
import { useField } from "formik";

interface InputComponentProps {
  label: string;
  name: string; // Set as required
  type?: string;
  required?: boolean;
  disabled?: boolean;
  secondlabel?: string;
  icon?: React.ReactNode;
  clickicon?: () => void;
}

const InputComponent: React.FC<InputComponentProps> = ({
  label,
  required,
  disabled,
  icon,
  secondlabel,
  clickicon,
  name,
  type = "text",
  ...props
}) => {
  const [field, meta] = useField({ name, type });

  const baseClass =
    "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6";
  const errorClass =
    "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";
  const normalClass =
    "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary-main";
  const disabledClass =
    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200";

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <label className="block text-sm font-medium text-gray-900">
            {label}
          </label>
          {required && (
            <span className="text-xs font-light text-gray-400">(required)</span>
          )}
        </div>
        {secondlabel && <div>{secondlabel}</div>}
      </div>

      <div className="relative mt-2">
        <input
          className={`${baseClass} ${
            meta.error ? errorClass : normalClass
          } ${disabledClass} ${icon ? "pr-10" : ""}`}
          {...field}
          {...props}
          disabled={disabled}
        />
        {icon && (
          <div
            onClick={clickicon}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            style={{ cursor: clickicon ? "pointer" : "default" }}
          >
            {icon}
          </div>
        )}
      </div>

      {meta.touched && meta.error && (
        <div className="text-red-600 mt-1 text-sm">{meta.error}</div>
      )}
    </div>
  );
};

export default InputComponent;
