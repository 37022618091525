const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const paymentStyles = [
  {
    id: 1,
    name: "ACTIVE",
    class: "bg-primary-main text-white",
  },
  {
    id: 2,
    name: "EXPIRED",
    class: "bg-slate-300 text-white",
  },
  {
    id: 3,
    name: "PENDING",
    class: "bg-yellow-500 text-white",
  },
  {
    id: 4,
    name: "CANCELLED",
    class: "bg-red-500 text-white",
  },
  {
    id: 5,
    name: "UNKNOWN",
    class: "bg-gray-700 text-white",
  },
  {
    id: 6,
    name: "PAID",
    class: "bg-emerald-400 text-white",
  },
];

const findStatusPayment = (paramsStatus: string) => {
  const status = paymentStyles.find(
    (x) => x.name.toLowerCase() === paramsStatus.toLowerCase()
  );

  return (
    <span
      className={classNames(
        status?.class || "",
        "inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium capitalize shadow-sm"
      )}
    >
      {status ? status.name : "-"}
    </span>
  );
};

export { findStatusPayment };
