import { useState } from "react";
import QRCode from "react-qr-code";
import { IconCopy } from "@tabler/icons-react";
import { toast } from "react-hot-toast";

import Tabs from "components/Tabs";
import Image from "components/Image";
import { RenderBankLogo } from "components/RenderBankPaymentMethod";

type PaymentResultData = {
  type: string | undefined;
  bank: string | undefined;
  va_number: string | undefined;
  account_name: string | undefined;
  qr_content: string | undefined;
};

interface PaymentResultProps {
  data: PaymentResultData | null;
  merchant: string;
}

const PaymentResult: React.FC<PaymentResultProps> = ({ data, merchant }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabClick = (params: number) => {
    setActiveTab(params);
  };

  const onCopyVa = async (va_number: string | undefined) => {
    if (!va_number) {
      toast.error("Invalid VA Number");
      return;
    }

    await navigator.clipboard.writeText(`${va_number}`);
    toast.success(`Copied ${va_number}`);
  };

  const checkPayment = () => {
    window.location.reload();
  };

  if (!data) {
    return (
      <div className="text-center text-gray-500">
        Data not available. Please try again.
      </div>
    );
  }

  if (data.type === "va") {
    return (
      <div>
        <div className="p-6 bg-[#F5F5F5] rounded-md shadow-md">
          <div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <span className="text-base">Virtual Account Number</span>
                  <button
                    onClick={() => onCopyVa(data.va_number)}
                    className="flex flex-row gap-2 items-center cursor-pointer"
                  >
                    <span className="text-2xl font-semibold">
                      {data.va_number ? data.va_number : "-"}
                    </span>
                    <IconCopy className="w-5 h-5 text-gray-700" />
                  </button>
                </div>
                <div className="hidden md:block">
                  <RenderBankLogo
                    title={data?.bank ? data?.bank : "Not Found"}
                    width="w-[120px]"
                    height="h-[50px]"
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col">
                  <span className="text-base">Merchant Name</span>
                  <div className="flex flex-row gap-2 items-center">
                    <span className="text-xl font-semibold">
                      {merchant ? merchant : "-"}
                    </span>
                  </div>
                </div>
                <div className="block md:hidden">
                  <RenderBankLogo
                    title={data?.bank ? data?.bank : "Not Found"}
                    width="w-[120px]"
                    height="h-[50px]"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-300 my-6" />

          <div className="p-3 border-y border-r border-l-4 border-primary-main rounded-lg flex flex-col bg-primary-light">
            <div className="mb-3 font-semibold text-sm">
              Protect Yourself Against Fraud
            </div>
            <span className="font-light text-sm">
              Ensure the merchant’s name, Payment amount and details are correct
              Always check before proceeding with payment
            </span>
          </div>

          <div className="mt-4">
            <Tabs
              tabs={[
                {
                  id: 0,
                  name: "MY BCA",
                  content: (
                    <div className="flex flex-col gap-3 py-6">
                      <div>
                        <span className="font-semibold">
                          Masuk ke Akun Anda
                        </span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>Pilih Transfer dan pilih Virtual Account.</li>
                          <li>Pilih Transfer ke tujuan baru.</li>
                        </ul>
                      </div>
                      <div>
                        <span className="font-semibold">Detail Pembayaran</span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>
                            Masukkan nomor Virtual Account dari e-commerce dan
                            klik Lanjut.
                          </li>
                          <li>
                            Pilih rekening sumber dana masukkan nominal dan klik
                            Lanjut.
                          </li>
                          <li>Cek detail transaksi, klik Lanjut.</li>
                          <li>Masukkan PIN dan transaksi berhasil.</li>
                        </ul>
                      </div>
                      <div>
                        <span className="font-semibold">Transaksi Selesai</span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>
                            Upon successful payment, Save the transaction
                            receipt or screenshot the screen as a proof of
                            payment.
                          </li>
                          <li>
                            Once the payment Transaction is completed, This
                            invoice will be updated automatically. This may take
                            up to 5 minutes
                          </li>
                        </ul>
                      </div>
                    </div>
                  ),
                },
                {
                  id: 1,
                  name: "ATM",
                  content: (
                    <div className="flex flex-col gap-3 py-6">
                      <div>
                        <span className="font-semibold">
                          Temukan ATM Terdekat
                        </span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>Masukkan kartu ATM.</li>
                          <li>Masukan Pin.</li>
                          <li>
                            Pilih menu “Transaksi Lainnya” – 'Transfer” – “Ke
                            Rek BCA Virtual Account”
                          </li>
                        </ul>
                      </div>
                      <div>
                        <span className="font-semibold">Detail Pembayaran</span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>
                            Masukkan nomor BCA Virtual Account: 39107+20+NRP.
                          </li>
                          <li>Layar ATM akan menampilkan konfirmasi.</li>
                          <li>
                            Ikuti instruksi untuk menyelesaikan transaksi.
                          </li>
                          <li>Ambil dan simpanlah bukti transaksi tersebut.</li>
                        </ul>
                      </div>
                      <div>
                        <span className="font-semibold">Transaksi Selesai</span>

                        <ul className="mt-2 list-decimal list-inside">
                          <li>Your transaction is successful</li>
                          <li>
                            Once the payment Transaction is completed, This
                            invoice will be updated automatically. This may take
                            up to 5 minutes
                          </li>
                        </ul>
                      </div>
                    </div>
                  ),
                },
              ]}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />

            <div className="hidden lg:block">
              <button
                onClick={() => checkPayment()}
                className={`my-3 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent bg-primary-main  px-4 py-2.5 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
              >
                {"CHECK STATUS"}
              </button>

              <div className="mx-auto max-w-fit mt-1">
                <div className="flex flex-row items-center gap-2">
                  <span className="text-[10px]">Powered by</span>
                  <Image
                    className="object-contain w-[45px] h-[20px]"
                    src={"/images/logos/logo.png"}
                    alt="Business Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-[#F5F5F5] rounded-md shadow-md">
      <div className="p-3 border-y border-r border-l-4 border-primary-main rounded-lg flex flex-col bg-primary-light">
        <div className="mb-3 font-semibold text-sm">
          Protect Yourself Against Fraud
        </div>
        <span className="font-light text-sm">
          Ensure the merchant’s name, Payment amount and details are correct
          Always check before proceeding with payment
        </span>
      </div>
      <div className="h-auto w-[340px] mx-auto my-6">
        <QRCode
          size={100}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={data?.qr_content ? data?.qr_content : "-"}
          viewBox={`0 0 100 100`}
        />
      </div>
      <div className="hidden lg:block">
        <button
          onClick={() => checkPayment()}
          className={`my-3 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent bg-primary-main  px-4 py-2.5 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
        >
          {"CHECK STATUS"}
        </button>

        <div className="mx-auto max-w-fit mt-1">
          <div className="flex flex-row items-center gap-2">
            <span className="text-[10px]">Powered by</span>
            <Image
              className="object-contain w-[45px] h-[20px]"
              src={"/images/logos/logo.png"}
              alt="Business Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
