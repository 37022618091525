import Skeleton from "react-loading-skeleton";

const LoadingMethod = () => {
  return (
    <div>
      <div className="my-8">
        <div className="mx-auto text-center max-w-sm mb-8">
          <div className="flex flex-col items-center gap-1">
            <div className="block w-full">
              <Skeleton height={28} containerClassName="max-container" />
            </div>
            <div className="block w-full">
              <Skeleton height={20} containerClassName="max-container" />
            </div>
          </div>
        </div>
        <div className="mx-auto text-center max-w-sm">
          <div className="flex flex-row gap-2 items-center">
            <div className="block w-full">
              <Skeleton height={28} containerClassName="max-container" />
            </div>
          </div>

          <div className="flex flex-row gap-2 items-center mt-2">
            <div className="block w-full">
              <Skeleton height={72} containerClassName="max-container" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-1.5 mt-10 mx-auto">
          <div className="block w-full">
            <Skeleton height={56} containerClassName="max-container" />
          </div>
          <div className="block w-full">
            <Skeleton height={56} containerClassName="max-container" />
          </div>
          <div className="block w-full">
            <Skeleton height={56} containerClassName="max-container" />
          </div>
          <div className="block w-full">
            <Skeleton height={56} containerClassName="max-container" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingMethod;
