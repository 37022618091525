import { useState } from "react";
import moment from "moment";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import { ItemOrder, DataDetailCheckout } from "../types";

import { rupiah } from "lib/currency";

interface SectionSummaryOrderMobileProps {
  data: DataDetailCheckout;
}

const SectionSummaryOrderMobile: React.FC<SectionSummaryOrderMobileProps> = ({
  data,
}) => {
  const [showOrder, setShowOrder] = useState(false);

  const addtionalData = [
    {
      label: "Tax",
      value: data?.tax ? `${data?.tax * 100}%` : "-",
    },
    {
      label: "Discount",
      value: data?.discount ? rupiah(data?.discount) : "-",
    },
    {
      label: "Fee",
      value: data?.fees ? rupiah(data?.fees) : "-",
    },
  ];

  return (
    <div className="block lg:hidden bg-neutral-100 p-4 rounded-md mt-8 mb-6 shadow-md">
      <span className="font-bold text-sm">
        Date:{" "}
        {data?.invoice_date
          ? moment(data?.invoice_date).format("DD MMM YYYY")
          : "-"}
      </span>
      <div
        className="flex flex-row items-center justify-between"
        onClick={() => setShowOrder(!showOrder)}
      >
        <div className="flex flex-row items-center text-xs text-gray-800 font-light">
          <span className="mr-1">Order</span>(
          <span className="w-[70%] block overflow-hidden whitespace-nowrap text-ellipsis">
            {data?.po_number ? data?.po_number : "-"}
          </span>
          )
        </div>
        {!showOrder ? (
          <IconChevronDown className="w-5 h-5" />
        ) : (
          <IconChevronUp className="w-5 h-5" />
        )}
      </div>
      {showOrder && (
        <div className="mt-4 pt-4 border-t border-gray-300">
          <ul className="divide-y divide-gray-100 flex flex-col gap-[8px]">
            {data?.invoice_payment_items.map((item: ItemOrder, idx: number) => (
              <li
                key={idx}
                className="flex justify-between items-center gap-x-6"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-bold text-gray-900 capitalize">
                      {item.item_name}
                    </p>
                    <p className="text-gray-600 text-xs font-medium">
                      {item.price ? rupiah(item.price) : "Rp -"} x{" "}
                      {item.quantity ? item.quantity : "0"}{" "}
                      <span className="text-[10px]">Qty</span>
                    </p>
                  </div>
                </div>
                <div className="shrink-0 flex flex-col items-start">
                  <p className="text-base text-gray-800 font-semibold">
                    {item ? rupiah(item.price * item.quantity) : "Rp -"}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex items-center justify-between text-gray-900 pt-3 pb-4">
            <dt className="text-gray-900 text-base font-semibold">Subtotal</dt>
            <dd className="text-gray-900 text-lg font-bold">
              {data?.subtotal ? rupiah(data?.subtotal) : "Rp -"}
            </dd>
          </div>
          <div className="flex flex-col gap-[5px] border-y border-gray-300 py-4">
            {addtionalData.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="flex items-center justify-between text-gray-900"
                >
                  <dt className="text-gray-900 text-[14px] font-medium">
                    {item.label}
                  </dt>
                  <dd className="text-gray-900 text-[14px] font-medium">
                    {item.value}
                  </dd>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex items-end justify-between text-gray-900 pt-4">
        <dt className="text-gray-900 text-base font-bold">Total</dt>
        <dd className="text-gray-900 text-2xl font-bold">
          {data?.total ? rupiah(data?.total) : "Rp -"}
        </dd>
      </div>
    </div>
  );
};

export default SectionSummaryOrderMobile;
