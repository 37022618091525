import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  IconBuildingBank,
  //   IconCreditCard,
  //   IconWallet,
  IconQrcode,
} from "@tabler/icons-react";
import moment from "moment";
import { toast } from "react-hot-toast";

import { get, post } from "network/api";

import BusinessName from "./BusinessName";
import Spinner from "components/Spinner";
import Typography from "components/Typography";
import CardPaymentMethod from "components/CardPaymentMethod";
import { findStatusPayment } from "components/BadgeStatus";
import SectionSummaryOrderMobileProps from "./SectionSummaryOrderMobile";
import PaymentResult from "./PaymentResult";
import { ItemPayment, DataDetailCheckout } from "../types";

import { rupiah } from "lib/currency";
import LoadingMethod from "./LoadingMethod";

interface MethodProps {
  data: DataDetailCheckout;
  pickPayment: boolean;
  setPickPayment: (value: boolean) => void;
}

type RouteParams = {
  id?: string;
};

type PaymentResultData = {
  type: string | undefined;
  bank: string | undefined;
  va_number: string | undefined;
  account_name: string | undefined;
  qr_content: string | undefined;
};

const Method: React.FC<MethodProps> = ({
  data,
  pickPayment,
  setPickPayment,
}) => {
  const { id } = useParams<RouteParams>();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [paymentType, setPaymentType] = useState<ItemPayment | undefined>();
  const [dataPayment, setDataPayment] = useState<PaymentResultData | null>(
    null
  );

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const getDetailMethodPayment = async (id: string) => {
    return get(`/checkout/v1/${id}/payment-method`);
  };

  const { data: dataMethod, isLoading } = useQuery({
    queryKey: ["getDetailMethodPayment", id],
    queryFn: async () => {
      try {
        if (!id) throw new Error("ID is required");
        return await getDetailMethodPayment(id);
      } catch (err) {
        const error = err as { detail: string };
        toast.error(error?.detail || "An unexpected error occurred");
        throw err;
      }
    },
    retry: false,
    enabled: !!id,
  });

  useEffect(() => {
    if (data.payment) {
      setPickPayment(true);
      setDataPayment({
        type: data.payment ? data?.payment?.string : "-",
        bank: data.payment ? data?.payment?.va?.bank : "-",
        va_number: data.payment ? data?.payment?.va?.va_number : "-",
        account_name: data.payment
          ? data?.payment?.va?.virtual_account_name
          : "-",
        qr_content: data.payment ? data?.payment?.qris?.qr_content : "-",
      });
    }
  }, [data, setPickPayment]);

  const handleOnSubmit = async (
    paymentType: ItemPayment | undefined,
    id: string
  ) => {
    if (!paymentType) {
      toast.error("Payment type is not selected!");
      return;
    }

    setLoadingSubmit(true);
    const payload = {
      type: paymentType.type ? paymentType.type : "",
      service_id: paymentType.service_id ? paymentType.service_id : "",
      customer_no: "",
      payment_method_id: paymentType.id ? paymentType.id : 0,
    };

    await post(`/checkout/v1/${id}/payment-method`, payload)
      .then((res) => {
        const { data } = res;
        setDataPayment({
          type: data.type ? data?.type : "-",
          bank: data.bank_info ? data?.bank_info?.bank_name : "-",
          va_number: data.bank_info ? data?.bank_info?.account_number : "-",
          account_name: data.virtual_account_name
            ? data?.virtual_account_name
            : "-",
          qr_content: data.qr_content ? data?.qr_content : "-",
        });
        setPickPayment(true);
        setLoadingSubmit(false);
      })
      .catch((err) => {
        toast.error(err.detail);
        setLoadingSubmit(false);
      });
  };

  if (isLoading) {
    return <LoadingMethod />;
  }

  return (
    <div className="pb-6 pt-8 px-4 lg:px-0">
      <BusinessName data={data} />
      <div className="my-8">
        <div className="mx-auto text-center">
          <Typography variant="body" className="font-medium">
            Pay Before{" "}
            {data?.payment_link_expiration
              ? moment(data?.payment_link_expiration).format(
                  "MMMM DD, YYYY [At] h:mm:ss A"
                )
              : "-"}
          </Typography>
          <Typography
            variant="h1"
            className="font-semibold mt-1.5 text-primary-main !leading-normal"
          >
            {data.total ? rupiah(data.total) : "Rp -"}
          </Typography>
        </div>

        <div className=" mt-3">
          {data.status && (
            <div className="mb-3 flex flex-row justify-end">
              {findStatusPayment(data.status)}
            </div>
          )}
          {pickPayment ? (
            <div className="flex flex-col gap-1.5">
              <PaymentResult data={dataPayment} merchant={data.merchant.name} />
              <SectionSummaryOrderMobileProps data={data} />
            </div>
          ) : (
            <div className="flex flex-col gap-1.5">
              <CardPaymentMethod
                dataImg={dataMethod?.data.va}
                name="BANK"
                icon={
                  <IconBuildingBank className="w-[22px] h-[22px] text-gray-800" />
                }
                isOpen={openIndex === 0}
                onToggle={() => setOpenIndex(openIndex === 0 ? null : 0)}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />

              <CardPaymentMethod
                dataImg={dataMethod?.data.qris}
                name="QR Payments"
                icon={
                  <IconQrcode className="w-[22px] h-[22px] text-gray-800" />
                }
                isOpen={openIndex === 1}
                onToggle={() => setOpenIndex(openIndex === 1 ? null : 1)}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />

              <button
                disabled={loadingSubmit}
                onClick={() => handleOnSubmit(paymentType, id || "")}
                className={`mt-10 w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent ${
                  loadingSubmit
                    ? "bg-gray-200 hover:bg-gray-400"
                    : "bg-primary-main hover:bg-primary-dark"
                } px-4 py-2.5 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
              >
                {loadingSubmit ? <Spinner /> : "BAYAR"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Method;
