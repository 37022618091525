import React from "react";

interface ImageProps {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, style, className }) => (
  <img src={src} alt={alt} style={style} className={className} />
);

export default Image;
