import { IconShoppingCartFilled } from "@tabler/icons-react";
import moment from "moment";

import Typography from "components/Typography";
import { ItemOrder, DataDetailCheckout } from "../types";

import { rupiah } from "lib/currency";

interface SectionSummaryOrderProps {
  data: DataDetailCheckout;
}

const SectionSummaryOrder: React.FC<SectionSummaryOrderProps> = ({ data }) => {
  const addtionalData = [
    {
      label: "Tax",
      value: data?.tax ? `${data?.tax * 100}%` : "-",
    },
    {
      label: "Discount",
      value: data?.discount ? rupiah(data?.discount) : "-",
    },
    {
      label: "Fee",
      value: data?.fees ? rupiah(data?.fees) : "-",
    },
  ];

  return (
    <section
      aria-labelledby="summary-heading"
      className="hidden md:block w-full flex-col bg-neutral-100 lg:flex col-span-2 relative overflow-y-auto"
    >
      <div className="h-[150vh] px-8 pt-8 pb-[120px]">
        <div className="flex flex-row items-center gap-2">
          <IconShoppingCartFilled />
          <Typography variant="h4" className="font-semibold">
            Order Summary
          </Typography>
        </div>

        <div className="flex flex-col gap-[2px] mt-1.5">
          <Typography variant="small">
            Invoice Number: {data?.invoice_number ? data?.invoice_number : "-"}
          </Typography>
          <Typography variant="small">
            Date:{" "}
            {data?.invoice_date
              ? moment(data?.invoice_date).format("DD MMM YYYY")
              : "-"}
          </Typography>
        </div>

        <div className="mt-6">
          <ul className="divide-y divide-gray-100 flex flex-col gap-[8px]">
            <li className="flex justify-center gap-x-6">
              <div className="min-w-0 flex-auto">
                <p className="text-xs text-gray-500 font-medium">Item</p>
              </div>
              <div className="shrink-0 flex flex-col items-end">
                <p className="text-xs text-gray-500 font-medium">Price</p>
              </div>
            </li>
            {data?.invoice_payment_items.map((item: ItemOrder, idx: number) => (
              <li
                key={idx}
                className="flex justify-between items-center gap-x-6"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold text-gray-900 capitalize">
                      {item.item_name}
                    </p>
                    <p className="text-gray-900 text-base font-semibold">
                      {item.price ? rupiah(item.price) : "Rp -"}
                    </p>
                    <p className="mt-0.5 truncate text-xs text-gray-500">
                      Quantity: {item.quantity}
                    </p>
                  </div>
                </div>
                <div className="shrink-0 flex flex-col items-start">
                  <p className="text-lg text-gray-900 font-bold">
                    {item ? rupiah(item.price * item.quantity) : "Rp -"}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="sticky bottom-0 right-0 left-0 w-full bg-neutral-50 shadow-[0_35px_70px_-15px_rgba(0,0,0,0.3)] px-8 py-4">
        <div className="flex items-center justify-between text-gray-900 py-2">
          <dt className="text-gray-900 text-base font-medium">Subtotal</dt>
          <dd className="text-gray-900 text-lg font-bold">
            {data?.subtotal ? rupiah(data?.subtotal) : "Rp -"}
          </dd>
        </div>
        <div className="flex flex-col gap-[5px] border-y border-gray-300 py-2">
          {addtionalData.map((item, idx) => {
            return (
              <div
                key={idx}
                className="flex items-center justify-between text-gray-900"
              >
                <dt className="text-gray-900 text-[14px] font-medium">
                  {item.label}
                </dt>
                <dd className="text-gray-900 text-[14px] font-medium">
                  {item.value}
                </dd>
              </div>
            );
          })}
        </div>
        <div className="flex items-end justify-between text-gray-900 pt-2">
          <dt className="text-gray-900 text-base font-bold">Total</dt>
          <dd className="text-gray-900 text-2xl font-bold">
            {data?.total ? rupiah(data?.total) : "Rp -"}
          </dd>
        </div>
      </div>
    </section>
  );
};

export default SectionSummaryOrder;
