import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CheckoutPage from "pages/Checkout";
import ErrorPage from "pages/ErrorPage";
import "./App.css";

function App() {
  return (
    <div className="h-full">
      <div className="min-h-screen">
        <Router>
          <Routes>
            <Route path="/:id" element={<CheckoutPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
