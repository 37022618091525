import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get } from "network/api";

import LoadingPage from "./Loading";
import FormUser from "./ComponentsPage/FormUser";
import SectionSummaryOrder from "./ComponentsPage/SectionSummaryOrder";
import Method from "./ComponentsPage/Method";
import Image from "components/Image";
import SuccessResult from "./ComponentsPage/SuccessResult";

type RouteParams = {
  id?: string;
};

type ApiError = {
  detail: string;
  status_code: number;
};

const CheckoutPages: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouteParams>();
  const [nameCustomer, setNameCustomer] = useState<string>("");
  const [pickPayment, setPickPayment] = useState(false);

  const getDetailCheckout = async (id: string) => {
    return get(`/checkout/v1/${id}/detail`);
  };

  const { data, isLoading, error } = useQuery<any, ApiError>({
    queryKey: ["getDetailCheckout", id],
    queryFn: async () => {
      if (!id) throw new Error("ID is required");
      return getDetailCheckout(id);
    },
    enabled: !!id,
    retry: false,
  });

  useEffect(() => {
    if (error) {
      navigate("/error", {
        state: {
          message: error
            ? error.detail
            : "Terjadi kesalahan yang tidak diketahui",
        },
      });
    }
  }, [error, navigate]);

  useEffect(() => {
    if (data?.data.customer.name) {
      setNameCustomer(data.data.customer.name);
    }
  }, [data]);

  const checkPayment = () => {
    window.location.reload();
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="h-screen relative">
      <main className="h-full grid grid-cols-6">
        <h1 className="sr-only">Checkout</h1>
        <section
          aria-labelledby="payment-heading"
          className={`${
            data?.data?.status === "ACTIVE"
              ? "col-span-6 lg:col-span-4"
              : "col-span-12 lg:col-span-12"
          }  overflow-y-auto relative`}
        >
          <div className="mx-auto max-w-xl h-full flex justify-center">
            <div className="w-full h-fit">
              {data?.data?.status === "ACTIVE" ? (
                <div>
                  {nameCustomer ? (
                    <Method
                      data={data?.data}
                      pickPayment={pickPayment}
                      setPickPayment={setPickPayment}
                    />
                  ) : (
                    <FormUser
                      data={data?.data}
                      setNameCustomer={setNameCustomer}
                    />
                  )}
                </div>
              ) : (
                <SuccessResult data={data?.data} />
              )}
            </div>
          </div>
        </section>

        {data?.data?.status === "ACTIVE" && (
          <SectionSummaryOrder data={data?.data} />
        )}
      </main>
      {pickPayment && data?.data?.status === "ACTIVE" && (
        <div className="block lg:hidden sticky bottom-0 left-0 right-0 w-full bg-white py-2 px-4 border-t border-gray-300">
          <button
            onClick={() => checkPayment()}
            className={`w-full flex flex-row items-center gap-2 justify-center rounded-md border border-transparent ${"bg-primary-main"}  px-4 py-2.5 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-offset-2`}
          >
            {"CHECK STATUS"}
          </button>
          <div className="mx-auto max-w-fit mt-1">
            <div className="flex flex-row items-center gap-2">
              <span className="text-[10px]">Powered by</span>
              <Image
                className="object-contain w-[45px] h-[20px]"
                src={"/images/logos/logo.png"}
                alt="Business Logo"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutPages;
