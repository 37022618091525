import React from "react";
import {
  IconChevronDown,
  IconBuildingBank,
  IconChevronUp,
} from "@tabler/icons-react";
import { RenderBankLogo, RenderBankLogoName } from "./RenderBankPaymentMethod";
import { TypeCardPaymentMethod } from "pages/types";

const CardPaymentMethod: React.FC<TypeCardPaymentMethod> = ({
  dataImg,
  name,
  icon = <IconBuildingBank className="w-[22px] h-[22px] text-gray-800" />,
  isOpen = false,
  onToggle,
  paymentType,
  setPaymentType,
}) => {
  return (
    <div>
      <div
        className="flex flex-row justify-between items-center border border-gray-300 py-3 px-3.5 cursor-pointer select-none"
        onClick={onToggle}
      >
        <div className="flex flex-row gap-3 items-center">
          {icon}
          <span className="text-base font-semibold text-gray-800">{name}</span>
        </div>

        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-row justify-between gap-2 items-center">
            {dataImg?.slice(0, 4).map((items, idx) => (
              <RenderBankLogo
                title={items.id === 0 ? "QRIS" : items.code}
                key={idx}
                idx={idx}
              />
            ))}
          </div>

          {isOpen ? (
            <IconChevronUp className="w-6 h-6 text-gray-800" />
          ) : (
            <IconChevronDown className="w-6 h-6 text-gray-800" />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="border-b border-l border-r border-gray-300 py-3 px-3.5 select-none">
          <div className="flex flex-col gap-2 py-3">
            {dataImg?.map((items, idx) => {
              const active = paymentType?.id === items.id;
              return (
                <label
                  htmlFor={items.id === 0 ? "QRIS" : items.code}
                  key={idx}
                  className={`flex flex-row justify-between items-center border rounded-md p-3 ${
                    active
                      ? "border-primary-main bg-primary-light"
                      : "border-gray-300 bg-white"
                  }`}
                >
                  <RenderBankLogoName
                    title={items.id === 0 ? "QRIS" : items.code}
                    key={idx}
                    idx={idx}
                  />
                  <input
                    id={items.id === 0 ? "QRIS" : items.code}
                    name={items.id === 0 ? "QRIS" : items.code}
                    type="checkbox"
                    aria-describedby={`${
                      items.id === 0 ? "QRIS" : items.code
                    }-description`}
                    className="h-4 w-4 rounded-full border-gray-300 text-primary-main focus:ring-primary-light"
                    checked={active}
                    onChange={() => setPaymentType?.(items)}
                  />
                </label>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardPaymentMethod;
