import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_CHECKOUT,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorDetail = {
      detail: error.response?.data?.message || error.message || "Unknown error",
      status_code: error.response?.status || 500,
    };
    return Promise.reject(errorDetail);
  }
);

export async function get<T = any>(url: string, config = {}) {
  return api.get<T>(url, config).then((response) => response.data);
}

export async function post<T = any>(url: string, data: any, config = {}) {
  return api.post<T>(url, data, config).then((response) => response.data);
}
